
import { Component, Vue } from "vue-property-decorator";
import { IUser } from "@/types/User";
import { UserService } from "@/services/user-service";
import { Defaults } from "@/helpers/Defaults";

@Component({
  name: "Login",
})
export default class Login extends Vue {
  show = false;
  user: IUser = new Defaults().user;
  showPassword = false;
  showErrorMessage = false;
  error = "";
  userService = new UserService();

  async login(): Promise<void> {
    localStorage.removeItem("dashboardFilters");
    localStorage.removeItem("fLoadingPlanIds");
    localStorage.removeItem("userRoles");
    this.show = true;
    let user = this.user;
    let password = user.password || "";
    let email = user.email || "";
    this.user.password = password.trim();
    this.user.email = email.trim();
    let result = await this.userService.login(this.user);
    if (!result.isSuccess){
      this.error = result.message;
      this.showErrorMessage = true;
      this.show = false;
    }
    let roles = await this.userService.getRoles();
    if (result.isSuccess) {
      localStorage.setItem("token", result.data.token);
      localStorage.setItem("expiration", result.data.expiration);
      localStorage.setItem("user", JSON.stringify(result.data.user));
      localStorage.setItem("userRoles", JSON.stringify(roles));
      await this.$router.push({
        name: "index",
      });
    }
    //console.log(result);
  }
}
